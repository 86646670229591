import request from '@/utils/request'
import { baseApi } from '@/api/apilist'
import { projectName } from '@/settings'

// 获取矿池信息
export function getProject() {
    return request({
        url: `${baseApi}/project_detail`,
        method: 'get'
    })
}

// 获取矿池信息
export function getProjectDetail(projectName) {
    return request({
        url: `${baseApi}/project_detail/${projectName}`,
        method: 'get'
    })
}

// 获取矿池汇总信息
export function getProjectSummary(projectName) {
    return request({
        url: `${baseApi}/block/summary?project=${projectName}`,
        method: 'get'
    })
}


// 获取：矿池算⼒实时图数据
export function getAddress_speed(projectName) {
    return request({
        url: `${baseApi}/block/address_speed?project=${projectName}`,
        method: 'get'
    })
}

/********************************************************** */
// 获取：用户机器信息
export function getUserMachine(projectName) {
    return request({
        url: `${baseApi}/machine?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}

//删除机器
// /api/v1/machine/31721
export function delMachine(machine_id) {
    return request({
        url: `${baseApi}/machine/${machine_id}`,
        method: 'DELETE',
        headers: {
            returnAll: true
        },
    })
}



//用户机器信息导出
// /api/v1/machine/export?project=aleo_test3_3
export function exportMachine(projectName) {
    // return `${baseApi}/distributor/export?project=${projectName}`;
    return request({
        url: `${baseApi}/machine/export?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}

// 获取用户机器算力信息
// /api/v1/network
export function getMachineMetrics() {
    return request({
        url: `${baseApi}/machine/metrics`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}
// 机器历史算力列表
// /api/v1/machine/metrics?owner_key=test2
export function getPowerHistory(machine_id) {
    return request({
        url: `${baseApi}/machine/${machine_id}/power_history?start_time=24h&end_time=now&step=10m`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}

// 分组列表(机器)
// /api/v1/machine_group?project=aleo_test3_3&miner_account__id=20693
export function getMachineGroup(projectName) {
    return request({
        url: `${baseApi}/machine_group?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}
// 添加分组
// /api/v1/machine_group
export function AddMachineGroup(data) {
    return request({
        url: `${baseApi}/machine_group`,
        method: 'post',
        data,
        headers: {
            returnAll: true
        },
    })
}

// 删除机器分组
// /api/v1/machine_group/194
export function delMachineGroup(machine_id) {
    return request({
        url: `${baseApi}/machine_group/${machine_id}`,
        method: 'DELETE',
        headers: {
            returnAll: true
        },
    })
}
// 机器加入分组
// /api/v1/machine_group/-2/add_machines
// /api/v1/machine_group/187/add_machines
// 参数：{machines: [31721,1212]}
// 备注：-2：ungroup ，187是组的id
export function editGroupofMachine(group_id,data) {
    return request({
        url: `${baseApi}/machine_group/${group_id}/add_machines`,
        method: 'POST',
        data,
        headers: {
            returnAll: true
        },
    })
}


/****************************用户收益****************************** */
// 计算总收入
export function getDistributorSummary(projectName) {
    return request({
        url: `${baseApi}/distributor/summary?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}

// 计算历史收入数据
export function getDistributorIncoming(projectName) {
    return request({
        url: `${baseApi}/distributor/all?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}
//历史收入导出
// /api/v1/distributor/export?project=aleo_test3_3
export function exportDistributorIncoming(projectName) {
    // return `${baseApi}/distributor/export?project=${projectName}`;
    return request({
        url: `${baseApi}/distributor/export?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}
// 计算历史支出数据
export function getDistributorPay(projectName) {
    return request({
        url: `${baseApi}/distributor/pay?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}

// 导出支出
export function exportDistributorPay(projectName) {
    return request({
        url: `${baseApi}/distributor/pay?project=${projectName}`,
        method: 'get',
        headers: {
            returnAll: true
        },
    })
}

//实时刷新token
export function tokenRefresh(data) {
  return request({
    url: `${baseApi}/token-refresh`,
    method: 'post',
    data,
    headers: {
      returnAll: true
    }
  })
}

// 获取今日预估收益 实时列表
export function getEpoch_distributor(projectName) {
  return request({
    url: `${baseApi}/epoch_distributor?project=${projectName}`,
	method: 'get',
	headers: {
		returnAll: true
	},
  })
}









