const site_zh = {
  loginPage: {
    title: '登錄賬號 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '網站登錄,賬號登錄,ZKLion平台登錄',
    description: 'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  registerPage: {
    title: '注冊賬號 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '網站註冊,賬號註冊,ZKLion平臺註冊',
    description: 'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  registerProtocolPage: {
    title: '注冊協議|用戶條款 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '註冊協議,用戶條款,ZKLion平臺用戶協議',
    description: 'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  registerActivePage:{ 
    title: '賬號激活 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '賬號激活,賬號註冊並使用',
    description: 'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  forgotPage: {
    title: '找回密碼|密碼忘記 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '找回密碼,密碼忘記',
    description: 'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  homePage: {
    title: ' ZKLion Pool - 安全且高效的數字貨Aleo幣礦池',
    keywords: '數字貨幣礦池,PPLNS礦池,SOLO礦池,挖礦服務,Aleo礦池',
    description:'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  workPage: {
    title: '機器管理|礦機管理 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '機器管理,礦機管理',
    description:'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  revenuePage: {
    title: '收益查看|收益管理 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '查看礦池收益,收益管理',
    description:'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，爲您提供優質的挖礦服務，少煩惱更省心！'
  },
  minerPage: {
    title: '賬戶設置 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '個人中心,賬號設置',
    description:'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，為您讓挖礦少煩惱更省心！'
  },
  safePage: {
    title: '個人設置|安全設置 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '個人設置,賬戶安全設置',
    description:'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，為您讓挖礦少煩惱更省心！'
  },
  personalPage: {
    title: '個人賬戶管理,個人設置 - ZKLion Pool安全且高效的數字貨幣Aleo礦池',
    keywords: '個人賬戶管理,個人設置',
    description:'ZKLion Pool專業高效、技術領先、安全且高效的數字貨幣礦池平臺。支持PPS、PPLNS兩種支付模式，費率低、收益高、質量優、服務優，為您讓挖礦少煩惱更省心！'
  },
  newsPage: {
    title: '新聞-ZKLion Pool，壹個安全高效的數字貨幣Aleo挖礦池',
    keywords: '個人帳戶管理、個人設置',
    description:'ZKLion Pool是一个专业、高效、技术领先、安全高效的数字货币矿池平台，支持PPS和PPLNS两种支付模式，低利率、高息、优质、优质的服务，让您少挖矿烦恼！'
  }
}

export default site_zh;