import { createRouter,createWebHistory } from 'vue-router'
// import IndexView from '@/pages/index/IndexView.vue'
// import SoloView from '@/pages/solo/index.vue'
// import About from '@/pages/about/index.vue'
// import LoginView from '@/pages/login/LoginView.vue'
// import Forgot from '@/pages/forgot/index'
import Register from '@/pages/register/index'
// import Management from '@/pages/miner/management/index'
// import Earnings from '@/pages/miner/earnings/index'
// import UserSetting from '@/pages/user/index'
import UserSetting from '@/pages/user/index'
// import SoloDetail from '@/pages/miner/solo/index'
// import NewsDetail from '@/pages/news'

import i18n from '@/i18n/i18n'

const routes = [
    {
      path: '/',
      name: 'index',
      component: ()=> import('@/pages/index/IndexView.vue'),
      meta: {
        title: i18n.global.tm('homePage.title'),
        keyword: i18n.global.tm('homePage.keywords'),
        description:i18n.global.tm('homePage.description')
      }
    },
    {
      path: '/solo',
      name: 'soloindex',
	  component: ()=> import('@/pages/solo/index.vue'),
      // component: SoloView,
      meta: {
        title: i18n.global.tm('homePage.title'),
        keyword: i18n.global.tm('homePage.keywords'),
        description:i18n.global.tm('homePage.description')
      }
    },
    {
      path: '/about',
      name: 'About',
	  component: ()=> import('@/pages/about/index.vue'),
      // component: About,
      meta: {
        title: i18n.global.tm('homePage.title'),
        keyword: i18n.global.tm('homePage.keywords'),
        description:i18n.global.tm('homePage.description')
      }
    },
    {
      path: '/login',
      name: 'login',
	  component: ()=> import('@/pages/login/LoginView.vue'),
      // component: LoginView,
      meta: {
        title: i18n.global.tm('loginPage.title'),
        keyword: i18n.global.tm('loginPage.keywords'),
        description:i18n.global.tm('loginPage.description')
      }
    }, 
    {
      path: '/forgot',
      name: 'forgot',
	  component:()=> import('@/pages/forgot/index'),
      // component: Forgot,
      meta: {
        title: i18n.global.tm('forgotPage.title'),
        keyword: i18n.global.tm('forgotPage.keywords'),
        description:i18n.global.tm('forgotPage.description')
      }
  },
   {
	  path: '/worker',
	  name: 'management',
		component:()=> import('@/pages/miner/management/index'),
    // component: Management,
      meta: {
        title: i18n.global.tm('workPage.title'),
        keyword: i18n.global.tm('workPage.keywords'),
        description:i18n.global.tm('workPage.description')
      }
  },
   {
    path: '/REVENUE',
    name: 'earnings',
    // component: Earnings,
	component:()=> import('@/pages/miner/earnings/index'),
    meta: {
      title: i18n.global.tm('revenuePage.title'),
      keyword: i18n.global.tm('revenuePage.keywords'),
      description:i18n.global.tm('revenuePage.description')
    }
  
  },
  // {
  //   path: '/user',
  //   name: 'user',
  //   component: UserSetting
  // },
  {
    path: '/solo/:address',
    name: 'solodetail',
    // component: SoloDetail,
	component:()=> import('@/pages/miner/solo/index'),
    meta: {
      title: i18n.global.tm('homePage.title'),
      keyword: i18n.global.tm('homePage.keywords'),
      description:i18n.global.tm('homePage.description')
    }
  },
  {
    path: '/news',
    name: 'news',
    // component: NewsDetail,
	component:()=> import('@/pages/news'),
    meta: {
      title: i18n.global.tm('newsPage.title'),
      keyword: i18n.global.tm('newsPage.keywords'),
      description: i18n.global.tm('newsPage.description')
    }
  },
  ...UserSetting,
  ...Register,
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
