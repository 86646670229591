// import UserMiner from '@/pages/user/miner/index.vue'
// import UserPersonal from '@/pages/user/personal'
// import UserSafe from '@/pages/user/safe'
import i18n from '@/i18n/i18n'

const ARouter = [
   {
    path: '/user/miner',
    name: 'UserMiner',
	component:()=> import('@/pages/user/miner/index.vue'),
    // component: UserMiner,
      meta: {
        title: i18n.global.tm('minerPage.title'),
        keyword: i18n.global.tm('minerPage.keywords'),
        description:i18n.global.tm('minerPage.description')
      }
    },  
    {
      path: '/user/personal',
      name: 'UserAccount',
      // component: UserPersonal,
	  component:()=> import('@/pages/user/personal'),
      meta: {
        title: i18n.global.tm('personalPage.title'),
        keyword: i18n.global.tm('personalPage.keywords'),
        description:i18n.global.tm('personalPage.description')
      }
    },
    {
      path: '/user/safe',
      name: 'UserSafe',
	  component:()=> import('@/pages/user/safe'),
      // component: UserSafe,
      meta: {
        title: i18n.global.tm('safePage.title'),
        keyword: i18n.global.tm('safePage.keywords'),
        description:i18n.global.tm('safePage.description')
      }
  }
]

export default ARouter