// import RegisterIndex from '@/pages/register/default/index'
// import Active from '@/pages/register/default/active'
// import SendEmail from '@/pages/register/default/sendEmail'
// import Service from '@/pages/service/index'
import i18n from '@/i18n/i18n'

const Router = [
  {
      path: '/signUp',
      name: 'signUp',
	  component:()=> import('@/pages/register/default/index'),
      // component: RegisterIndex,
      meta: {
        title: i18n.global.tm('registerPage.title'),
        keyword: i18n.global.tm('registerPage.keywords'),
        description:i18n.global.tm('registerPage.description')
      }
    },
    {
      path: '/active',
      name: 'active',
      // component: Active,
	  component:()=> import('@/pages/register/default/active'),
      meta: {
        title: i18n.global.tm('registerActivePage.title'),
        keyword: i18n.global.tm('registerActivePage.keywords'),
        description:i18n.global.tm('registerActivePage.description')
      }
    },
    {
      path: '/send-email',
      name: 'active',
      // component: SendEmail,
	  component:()=> import('@/pages/register/default/sendEmail'),
      meta: {
        title: i18n.global.tm('homePage.title'),
        keyword: i18n.global.tm('homePage.keywords'),
        description:i18n.global.tm('homePage.description')
      }
  },
  {
    path: '/service',
    name: 'service',
    // component: Service,
	component:()=> import('@/pages/service/index'),
    meta: {
        title: i18n.global.tm('registerProtocolPage.title'),
        keyword: i18n.global.tm('registerProtocolPage.keywords'),
        description:i18n.global.tm('registerProtocolPage.description')
      }
  },
]

export default Router