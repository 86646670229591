const site_en = {
  loginPage: {
    title: 'Login account - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Website login, account login, ZKLion platform login',
    description: 'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  registerPage: {
    title: 'Account Registration - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Website registration, account registration,ZKLion platform registration',
    description: 'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  registerProtocolPage: {
    title: 'Registration Agreement | User Term - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Registration Agreement, User Term, ZKLion Platform User Agreement',
    description: 'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  registerActivePage:{ 
    title: 'Account activation - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Account activation, account registration and use',
    description: 'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  forgotPage: {
    title: 'Retrieve password | Password forgotten - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Retrieve password, Password forgotten',
    description: 'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  homePage: {
    title: 'ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Digital currency pool, PPLNS mining pool, SOLO Mining pool, Mining service, Aleo mining pool',
    description:'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  workPage: {
    title: 'Machine management | Mine Management - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Machine management, Mine Management',
    description:'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  revenuePage: {
    title: 'Earnings See | Earnings Management - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Earnings See, Earnings Management',
    description:'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  minerPage: {
    title: 'Account setting - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Personal center, account Settings',
    description:'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  safePage: {
    title: 'Personal settings | Security settings - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Personal settings, Account Security settings',
    description:'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  personalPage: {
    title: 'Personal account management | Personal settings - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Personal account management, Personal settings',
    description:'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  newsPage: {
    title: 'Newsfeed - ZKLion Pool - Secure and efficient digitalcurrency Aleo mining pools',
    keywords: 'Personal account management, Personal settings',
    description:'ZKLion Pool is a professional, efficient, technology-leading, safe and efficient digital currency mining pool platform, supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  }
}

export default site_en;