<template>
    <header  v-if="$store.state.ismobile == false"  class="header">
        <div class="container">
            <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-2 col-2 d-flex flex-center">
                    <div class="logo">
                        <a href="/"><img src="@/assets/images/logo.png" alt="Cryptorius Logo"></a>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-9">
                    <div class="main-menu">
                        <div class="nav-menu text-right">
                            <ul>
                                <li v-bind:class="{ 'active': routerName == '/' }"><a href="/">{{ $t('header.menu.home') }}</a></li>
                                <li v-if="token=='' || user_id>0"  v-bind:class="{ 'active': routerName == '/worker' }" ><a href="/worker">{{ $t('header.menu.worker') }}</a></li>
                                <li v-else><a data-bs-toggle="modal" data-bs-target="#addMinerGuide">{{ $t('header.menu.worker') }}</a></li>
                                <li v-bind:class="{ 'active': routerName == '/REVENUE' }"><a href="/REVENUE">{{ $t('header.menu.revenue') }}</a></li>
                                <!-- <li><a href="#">{{ $t('header.menu.help') }}</a></li> -->
                            </ul>       
                        </div>
                         <div class="login-box">
                                <a v-if="token == '' || token == undefined" href="/login" class="button">{{ $t('header.userStatus.login') }}</a>
                                <span v-else>
                                <div class="dropdown user-state">
                                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="iconfont icon-user">&#xe646;</i><span style=" margin-left: 5px; max-width: 60px; display: inherit;position: absolute;
    top: 30%;">{{ username }}</span>
                                </a>
                                <ul class="dropdown-menu" style="width: 220px;">
                                    <li v-if="username!=''" style="height: 45px; line-height: 45px; border-bottom: #6d668b solid 1px;">
                                          <div class="row justify-content-evenly">
                                            <div class="col-6 text-left"  style="font-size: 14px;">
                                                <span style="margin-left:10px; ">{{ username }} </span>
                                            </div>
                                            <div class="col-6 text-right" style="font-size: 12px;padding-left: 0px;">
                                                <span style="margin-right:10px; ">{{ total_power }} s/s</span>
                                            </div>
                                          </div>
                                    </li>
                                    <li style="height: 45px; line-height: 45px; margin-top:8px">
                                        <div class="row  justify-content-between">
                                        <div class="col-6">
                                            <a href="/user/miner">
                                                <button class="button border-rad-18" style="font-size: 14px;padding:8px 10px; margin-left:10px;  width: 100%;" >{{ $t('header.userStatus.setting') }}</button>
                                            </a>
                                        </div>
                                        <div class="col-6">
                                        <button class="button  border-rad-18"  style="font-size: 14px;padding:8px 10px;margin-left:-10px; width: 100%;"  @click="logout()">{{ $t('header.userStatus.exit') }}</button>
                                        </div>
                                        </div>
                                    </li>
                                </ul>
                                </div>
                                </span>
                         </div> 
                    </div>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-1 col-1">
                        <div class="dropdown language-set" id="language-set">
                                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="iconfont iconimg"> &#xe6c2;</i> 
                                    <span style="min-width: 45px;display: inline-grid;">{{ lang_selected == 'en' ? 'English' : "中文" }}</span>
                                        <i class="iconfont">&#xe6b9;</i>
                                    </a>
                                    <div  class="dropdown-menu">
                                        <a href="javascript:void(0)" data-value="en" class="dropdown-item"  @click="changeLang('en')">English</a>
                                        <a href="javascript:void(0)" data-value="zh"  class="dropdown-item" @click="changeLang('zh')">中文</a>
                                    </div>
                        </div>
				
                </div>        
            </div>
            <div v-if="$store.state.is_show_notice" id="headerTips" class="header-tips ticker" style="height: 22px;overflow:hidden">
                <div class="container">
                    <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="message-container" id="messageContainer"  ref="myMessageContainer">
							
						<!-- <div class="message-item" v-for="item in notices"> -->
						<div class="message-item">
							   <i class="iconfont icon-call ">&#xe683;</i> 
								<a href="">{{$t('header.ad.title1')}}</a>
								<a class="more-tips" href="https://mp.weixin.qq.com/s/gutXtcMi4SErdvbih4EdVw" style="margin-left: 30px;" target="_blank">More &gt;</a>
						</div>
						<div class="message-item">
							   <i class="iconfont icon-call ">&#xe683;</i> 
								<a href="">{{$t('header.ad.title2')}}</a>
								<a class="more-tips" href="https://x.com/theLeoWallet/status/1753492659711389951" style="margin-left: 30px;" target="_blank">More &gt;</a>
						</div>
						
                        </div>
                        <div data-bs-theme="dark" style="position: absolute;right: 80px; z-index: 999;top: 8px;">
                                <button type="button" class="btn-close" style=" font-size: 12px;" aria-label="Close" @click="close_notice()"></button>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        </div>
    </header>
    <!-- Navbar.vue -->
    <nav v-else class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a  href="/" class="navbar-brand" style="width: 50%;"><img src="@/assets/images/logo.png"  alt="Cryptorius Logo"></a>
	<button 
	    class="navbar-toggler"
        type="button"
		data-bs-toggle="collapse" 
		href="#navbarNav" 
		role="button" 
		aria-expanded="false" 
		aria-controls="navbarNav">
	
    <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse multi-collapse" id="navbarNav">
        <ul class="navbar-nav">
        <li v-bind:class="{ 'active': routerName == '/', 'nav-item': true }">
            <a class="nav-link" href="/">{{ $t('header.menu.home') }} </a>
        </li>
        <li v-if="token == '' || user_id > 0"  v-bind:class="{ 'active': routerName == '/worker', 'nav-item': true }" >
            <a class="nav-link" href="/worker">{{ $t('header.menu.worker') }}</a>
        </li>
        <li v-else v-bind:class="{ 'active': routerName == '/worker', 'nav-item': true }"> 
            <a class="nav-link" data-bs-toggle="modal" data-bs-target="#addMinerGuide">{{ $t('header.menu.worker') }}</a>
        </li>

        <li v-bind:class="{ 'active': routerName == '/REVENUE', 'nav-item': true }">
            <a class="nav-link" href="/REVENUE">{{ $t('header.menu.revenue') }}</a>
        </li>
        <li>
            <a v-if="token == '' || token == undefined" href="/login" class="button">{{ $t('header.userStatus.login') }}</a>
            <span v-else>
            <div class="dropdown user-state">
            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="iconfont icon-user">&#xe646;</i><span style="color:#888; margin-left: 5px; max-width: 60px; display: inherit;position: absolute;
top: 15px;">{{ username }}</span>
            </a>
            <ul class="dropdown-menu" style="width: 220px;">
                <li v-if="username != ''" style="height: 45px; line-height: 45px; border-bottom: #6d668b solid 1px;">
                        <div class="row justify-content-evenly">
                        <div class="col-6 text-left"  style="font-size: 14px;">
                            <span style="margin-left:10px; ">{{ username }} </span>
                        </div>
                        <div class="col-6 text-right" style="font-size: 12px;padding-left: 0px;">
                            <span style="margin-right:10px; ">{{ total_power }} s/s</span>
                        </div>
                        </div>
                </li>
                <li style="height: 45px; line-height: 45px; margin-top:8px">
                    <div class="row  justify-content-between">
                    <div class="col-6">
                        <a href="/user/miner">
                            <button class="button border-rad-18" style="font-size: 12px;padding:3px; margin-left:10px;  width: 100%;" >
                                {{ $t('header.userStatus.setting') }}
                            </button>
                        </a>
                    </div>
                    <div class="col-6">
                    <a href="/user/miner">
                    <button class="button  border-rad-18"  style="font-size: 12px;padding:3px;margin-left:-10px; width: 100%;"  @click="logout()">{{ $t('header.userStatus.exit') }}</button>
                    </a>
                    </div>
                    </div>
                </li>
            </ul>
            </div>
            </span>
        </li>
        <li>
            <div class="dropdown language-set" id="language-set">
                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="iconfont iconimg"> &#xe6c2;</i> 
                <span style="min-width: 45px;display: inline-grid;">{{ lang_selected == 'en' ? 'English' : "中文" }}</span>
                    <i class="iconfont">&#xe6b9;</i>
                </a>
                <div  class="dropdown-menu">
                    <a href="javascript:void(0)" data-value="en" class="dropdown-item"  @click="changeLang('en')">English</a>
                    <a href="javascript:void(0)" data-value="zh"  class="dropdown-item" @click="changeLang('zh')">中文</a>
                </div>
            </div>
        </li>
        </ul>
    </div>
    </nav>
    <AddMinerGuide></AddMinerGuide>
</template>
<script>

import { getMachineMetrics } from '@/api/common';
import { getToken, removeToken, getMinerAcount, setMinerAcount,getProjectName, setProjectName } from '@/utils/auth'
import AddMinerGuide from './AddMinerGuide.vue';
import { getProject } from '@/api/common';
import { getAccounts } from '@/api/user';
import { getNotice } from '@/api/aleo';
export default {
    name: 'Header',
    components: {
        AddMinerGuide
    },
    data() {
        return {
            lang_selected: this.$i18n.locale,
            notices:[],
            token: getToken(),
            machine_power:"",
            username: '',
            user_id: '',
            total_power:0,
			projectList:[],
            project_name: getProjectName(),
            routerName: window.location.pathname
        }
    },
	 beforeCreate() {
		let $this = this;
	    this.username=''
	    let project_name = getProjectName()
		project_name = project_name == '' ? this.$store.state.project : project_name
		if (project_name == '' || project_name == undefined || project_name == 'undefined') {
			getProject().then(res => {
			    if (res.length > 0) {
			        setProjectName(res[0].name);
			        $this.project_name = res[0].name
					$this.projectList = res;// 获取项目列表
			        this.$store.commit("setProject", res[0]);  
			    }
			})
		} else if (this.$store.state.project == '') { 
		    this.$store.commit("setProject", project_name);
		}
	  },
    created() {
		this.get_Notice();
		let project_name = getProjectName()
		project_name = project_name == '' ? this.$store.state.project : project_name
		if (project_name == '' || project_name == undefined || project_name == 'undefined') {
			this.get_Projects()
		}
		this.token = getToken()
		if (this.token != '' && this.token != undefined) {
		    this.getIntData();
		    this.get_MachineMetrics();
		}
    },
    mounted() {

    },
    watch: {
    },
    methods: {
        init_notice() { 
            var messageContainer = $('#messageContainer');
            var messageItems = $('.message-item');
            // 设置当前消息项的索引
            let currentIndex = 0;
            let origin_item
            if (this.notices.length == 0) { 
                return false;
            }
            // 定时器，每隔 10 秒切换消息
            setInterval(function () {
                // 隐藏当前消息项
                if (messageItems[currentIndex] == undefined) { 
                    return false;
                }
                
                messageItems[currentIndex].style.animation = "slide-up 0.5s linear infinite"
                origin_item = messageItems[currentIndex]
                // 更新索引，循环到第一个消息项
                currentIndex = (currentIndex + 1) % messageItems.length;
                if (messageItems[currentIndex] == undefined) {
                    return false;
                }
                setTimeout(function () {
                    origin_item.style.display = 'none'
                    origin_item.style.animation = ''
                    messageItems[currentIndex].style.display = 'block';
                }, 500); // 2秒的延迟，与过渡时间一致
            }, 5000); // 切换间隔为 10 秒，可根据需要调整
        },
        get_MachineMetrics() {
            let $this = this;
            getMachineMetrics().then(res => {
                if (res.code == 200) {
                    if (Object.keys(res.data).indexOf('machine_15m_power') != -1) { 
                        Object.keys(res.data.machine_15m_power).forEach((key) => {
                            $this.total_power = parseFloat($this.total_power) + parseFloat(res.data.machine_15m_power[key])
                            // return parseFloat(hashrate[keyName]).toFixed(0)
                        })
                    }
                    $this.total_power = parseFloat($this.total_power).toFixed(0);
                }
            })
        },
        changeLang(lang) {
            this.$i18n.locale = lang;
            this.$store.commit("switchLang", lang);
            this.lang_selected = lang;
            location.reload();
            // locale.value = lang
            // localStorage.setItem('lang', lang)// 重要！下面遇到问题里解释
        },
		changeNet(net){
			this.$store.commit("setProject", net);
			this.project_name = net
			location.reload();
		},
        close_notice() { 
            console.log("");
            this.$store.commit("setIs_show_notice", false);
        },
        logout() {
            removeToken();
            this.token = ""
            this.username = ""
            this.user_id = ""
            window.location.href='/'
        },
        get_Projects() {
            let $this = this;
            getProject().then(res => {
                if (res.length > 0) {
                    setProjectName(res[0].name);
                    $this.project_name = res[0].name
					$this.projectList = res;// 获取项目列表
                    this.$store.commit("setProject", res[0]);
                    
                }
            })
        },
        getMinerAcount() {
            const $this = this;
            if (this.token == '' || this.token == undefined) { 
                return false;
            }
            getAccounts('aleo_test3_3').then(res => {
                if (res.length > 0) {
                    const miner = JSON.stringify({ id: res[0].id, name: res[0].name })
                    $this.username = res[0].name;
                    $this.user_id = res[0].id;
                    setMinerAcount(miner)
                }
            })
        },
        getIntData() { 
            const $this = this;
            let minerAcount_str = getMinerAcount();
            if (minerAcount_str != undefined && minerAcount_str != '' && minerAcount_str != 'undefined') {
                $this.username = JSON.parse(minerAcount_str).name;
                $this.user_id = JSON.parse(minerAcount_str).id;
            } else{
                $this.getMinerAcount();
            }
        },
        get_Notice() {
            const $this = this;
            getNotice().then(res => {
                if (res.code = 200) { 
                    $this.notices = res.data==null?[]: res.data;
                    this.$nextTick(() => {
                        $this.init_notice();
                    });   
                }
            })
        },
        toggleMenu() {
            var menu = $('#navbarNav');
            menu.classList.toggle('show');
        }
        
        
    }
}
</script>
<style lang="scss">
@import './index.scss';
.language-set .dropdown-menu{
    min-width: 105px;
    font-size: 14px;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    a{
        &:hover{
            background:#d0e2f5;
        }
    }
}

header .header-tips {
   /* text-shadow: 2px 2px 4px black;*/
    position: absolute;
    left: -50px;
    right: -50px;
    z-index: 1;
    height: 40px !important;
    background: hsla(0,0%,100%,.06);
    font-size: 16px;
    line-height: 22px;
    color: #f8e71c;
    .tickerHook{
        left: 40%;
    }
}

/* 自定义 CSS 样式 
.scroll-message {
    height: 100px;
    overflow: hidden;
    position: relative;
    text-align: center;
    margin: 0 auto;
}

.message-container {
    position: absolute;
    top: 0;
    animation: scroll-up 5s linear infinite;
}
*/
/* 自定义动画 */


.message-container {
    height: 40px;
    font-size: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
}


/* 设置消息项的初始状态 */
.message-item {
    display: none;
    
}
.message-item a{
    color: #fff;
    font-size: 14px;
    position:relative;
    bottom: 2px;
}

/* 设置第一个消息项的显示状态 */
.message-item:first-child {
    display: block;
}
.message-container .icon-call {
    font-size: 22px;
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.8);
}
@keyframes slide-up {
    0% {
    transform: translateY(0);
    }
    100% {
    transform: translateY(-150%);
    }
}
.header.sticky #headerTips{
    background: #1c0252;
}


</style>