import site_zh from "./module/site_zh"

const zh = {
  header: {
    switchLanguage: "切換語言",
    copyText: '復製成功',
    remindAccount: '請先創建礦工帳戶',
    language: {
      zh: "zh",
      zhName: "中文",
      en: "en",
      enName: "英文"
    },
    menu: {
      home: "首頁",
      worker: "礦工管理",
      revenue: "收益",
      help: "教程",
      solo: "SOLO"
    },
    userStatus: {
      register: "註冊",
      login: "登錄",
      setting: "賬戶設置",
      exit: "退出"
    },
	ad:{
		title1:'ZKLion正式推出Aleo TestNet Beta版本算法鋤頭',
		title2:'Leo wallet 领水教程'
	}
  },
  footer: {
    "title": "2024 ZKLion 保留所有权利"
  },
  banner: {
    // title: "最值得信賴",
    // title2: "Aleo礦池",
    // info: "零知識證明算法深度優化 && Aleo測試網深度參與"
    title: "The Most Trusted",
    title2: "Aleo Mining Provider",
    info: "Optimization of the zkp calculation and Zk-snarks algorithm"
  },
  home: {
    dataShow: {
      help: "教程",
      item1: {
        title: '礦池算力',
        info: '礦池十五分鐘內每秒solution的數量',
      },
      item2: {
        title: '全網算力',
        info: '全網十五分鐘內每秒solution的數量',
      },
      item3: {
        title: '日平均收益',
        // info: '昨日實際收益',
        info:'昨日理论收益 (假设每个solution平均2个Aleo积分*)'
      },
      item4: {
        title: 'Aleo 價格',
        info: 'Aleo在 gate.io 平臺的實時價格',
      },
      item5: {
        title: '鏈上信息',
        list1: {
          title: '高度',
        },
        list2: {
          title: '挖礦地址',
          info: '用來連接礦池的地址',
        },
        list3: {
          title: 'Proof目標',
          info: '最新區塊的證明目標',
        },
      },
      item6: {
        title: '日平均收益計算',
      },
      item7: {
        title: '支付信息',
        list1: {
          title: '費率',
        },
        list2: {
          title: '起付額',
          info: '礦池提現最小金額',
        },
      },
      item8: {
        title: '挖礦教程',
      },
      item9: {
        title: '第三方OS集成',
      }
    },
    service: {
      title: '服務模式',
      step1: {
        title: '客戶託管',
        info: '全面保管我們客戶提供的所有設備，將由 ZKLion DevOps 團隊遠程操作和維護',
      },
      step2: {
        title: 'Solo 模式',
        info: '官方獎勵直接分配給自己的地址，無需通過礦池二次分配，不用擔心礦池拖延提現',
      },
      step3: {
        title: 'Pool 模式',
        info: '整合計算資源，提高挖礦獎勵的穩定性和頻率，減小了挖礦的難度，提供更廣泛的社區支持',
      },
    },
    advantage: {
      title: '為什麼選擇我們',
      step1: {
        title: '卓越',
        info: 'ZKLion的研發團隊針對零知識證明算法進行了大量的優化，這讓我們在 Filecoin Space Race 中排名前十，在 Aleo Testnet 中排名靠前。',
      },
      step2: {
        title: '全棧式託管服務',
        info: 'ZKLion 擁有最專業的機房、網絡、運維團隊，7*24*365保障礦池的穩定運行',
      },
      step3: {
        title: '監控系統',
        info: '自研監控分析系統，集數據采集、可視化、監控告警、數據分析於一體，為礦機7*24保駕護航',
      },
    },
  },
  minerMgt: {
    title: "Aleo 挖礦錢包地址",
    noData: '暫無數據',
    return: '返回',
    add: "添加",
    sort: "排序",
    delTitle: "刪除主機",
    delInfo: "你確定要刪除主機？",
    groupname: "分組名",
    ungroup: '未分組',
    all: '所有',
    online: '在線礦機',
    offline: '離線礦機',
    summary: "匯總",
    help: '挖礦教程',
    step: {
      item1: "第一步",
      item2: "第二步",
      item3: "第三步",
    },
    group: {
      add: {
        title: '添加組',
        title2: "組名",
        title2_placeholder: "請輸入組名"
      },
      allMinerNum: {
        title: "全部",
        info: "帳戶下所有礦機"
      },
      notGrouped: {
        title: "未分組",
        info: "帳戶下沒有分組的礦機"
      },
    },
    miner: {
      noMiner: {
        title: "礦機添加方法",
        title1: "礦機未顯示？",
        tab1: {
          title: "下載挖礦軟件",
          info: "下載系統版本對應的挖礦程序",
          info1: "添加執行權限",
        },
        tab2: {
          title: "挖礦參數設置",
          info: "查看挖礦程序啟動示例",
          info1: "輸入對應的用戶名及挖礦賬戶名",
          info2: "啟動挖礦程序"
        },
        tab3: {
          title: "完成",
          info: "查看挖礦程序的日誌輸出",
          info1: "礦機將在啟動2分鐘內輸出算力"
        },
        tutorial: "查看詳細挖礦教程",
      },
      minerInfo: {
        title: "總機器數",
        tab1: {
          title: "全部",
          info: "全部 = 在線 + 離線"
        },
        tab2: {
          title: "在線",
          info: "在線礦機"
        },
        tab3: {
          title: "離線",
          info: "超過10分鐘未提交算力的礦機"
        },
        minerList: {
          worker: "礦機名稱",
          hashrate15m: "15分鐘算力",
          hashrate1h: "1小時算力",
          hashrate24h: "24小時算力",
          lastShare: "最近提交時間",
          group: "組名"
        },
      },
    },
  },
  revenue: {
    title: '收益總覽',
    totalIncome: "總收入",
    totalPayout: "總支出",
    yesterdayRevenue: "昨日收益",
    yesterdayRevenue_info: "當日00:00 UTC前24小時收益。",
	
	yesterdayPending: "待結算收益",
	yesterdayPending_info: "每日 0:00 UTC（北京時間8點）系統將開始核算昨日收益並發放",
	
    todayRevenue: "今日已挖(預估)",
    todayRevenue_info1: "當前的收益預估數據,是按照PPLNS模式計算給出的理論參攷值,可能與您的實際收益產生偏差。",
	
    creditTitle: "有效積分",
    credit_info: "本次測試網證明者激勵計劃已於美國東部時間7月1日中午12點正式開啟，7月15日結束（具體結束時間待官方公布），僅在此期間獲取到的積分視為有效",

    pendingSettlement: "待結算",
    incomeRecords: "收入紀錄",
    payoutRecords: "支出紀錄",
    export: "匯出",
    summary: "匯總",
    income_Records: {
      title: "收入記錄",
      // title_info:'每日00:00 UTC結算過去24小時收益',
      settlement_Time: "結算時間",
      settlement_Time_info: "每日00:00 UTC結算過去24小時收益",
      amount: "數量",
      description: "備註",
      desc_default_val:'挖礦收益',
	  valid_title:'有效性',
	  valid:'有效',
	  invalid:'无效',
    },
    payout_Records: {
      title: "支出記錄",
      Date: "日期",
      Amount: "數量",
      Address: "地址",
      Status: "支付狀態",
    },
	epoch_Records: {
	  title: "Epoch",
	  date: "日期",
	  account: "賬户",
	  credit: "Credit",
	},
  },
  userState: {
    loginTitle: "客 户 登 錄",
    name: {
      placeholder: '請輸入用戶名',
      error: '用戶名不能為空'
    },
    password: {
      label: '密碼',
      relabel: '确认密碼',
      placeholder: '請輸入密碼',
      placeholder_repeat: '请再次输入密码',
      error: '密碼不能為空',
      error_num: '密码长度必须在8-15之间',
      error_uppercase: '密码必须包含1个大写字母和1个数字'
    },
    email: {
      label: '郵箱',
      placeholder: '請輸入郵箱',
      check: '郵件驗證',
      error: '郵箱不能為空',
      errorFormat: '邮箱格式错误'
    },
    referralCode: {
      label:'邀請碼(可選)',
      placeholder: '請輸入邀請碼',
      error: '邀請碼错误',
    },
    code: {
      label: '驗證碼',
      placeholder: '請輸入驗證碼',
      getCode: "獲取驗證碼",
      error: '驗證碼不能為空',
      resendRemind: '秒後重新發送'
    },
    mfa: {
      title: "Google驗證",
      label: "MFA",
      placeholder: "請輸入MFA驗證碼",
      remind: 'Google 身份驗證設備丟失?'
    },
    mfaInfo: {
      title1: "如果您已備份過雙重身份驗證器的16位密鑰，可通過密鑰快速找回，無需重置。",
      title2: "如果密鑰丟失，請聯繫在線客服或發送郵件至"
    },
    agreenProtocol: {
      label: "我已閱讀並同意 ZKLion",
      Protocol: "《服務條款》",
      error: '請先閱讀並同意服務條款。'
    },
    resendEmail: {
      title: '激活電子郵件已成功發送至電子郵件地址',
      lebel: '如果您沒有收到郵件，請嘗試以下方法:',
      item1: '1、請確保您輸入正確的電子郵件地址',
      item2: '2、請檢查您的垃圾郵件文件夾',
      item3: '3、將 ZKLion 添加到您的電子郵件地址白名單',
      item4: '4、重新發送電子郵件',
      item5: '5、聯系我們 '
    },
    register: '註 冊',
    registerText: '註冊賬號',
    
    login: '登 錄',
    reset: '重置密碼',
    forgot: '忘記密碼',
    forgotText: "忘記密碼?",
    noAccountText: "沒有帳號?",
    createAccountText: '建立帳號',
    haveAccountText: "已有帳號",
    
  },
  valid: {
    empty: "這個字段不能為空.",
    must_6_charts: "該字段至少有 6 個字符",
    username_check: "必須以字母開頭並包含 5-15 個字符",
    mfa: {
      empty: 'Google 身份驗證不能為空'
    },
    password: {
      error: '密碼不能為空',
      error_num: '密码长度必须在8-15之间',
      error_uppercase: '密码必须包含1个大写字母和1个数字'
    },
    email: {
      error: '郵箱不能為空',
      errorFormat: '邮箱格式错误'
    },
    address: {
      error: 'Aleo 錢包地址不能為空',
      errorFormat: 'Aleo 錢包地址格式错误'
    }
  },
  userSetting: {
    "title": '帳戶設定',
    "miningAccount": {
      "item1": {
        "title": "挖礦賬戶",
        "info": ""
      },
      "item1-1": {
        "title": "創建挖礦賬戶",
        "info": ""
      },
      "item1-2": {
        "title": "賬戶名",
        "info": ""
      },
      "item1-3": {
        "title": "請輸入賬戶名",
        "info": ""
      },
      "item1-4": {
        "title": "請輸入備註",
        "info": ""
      },
      "item2": {
        "title": "備註",
        "info": ""
      },
      "item3": {
        "title": "錢包地址",
        "info": ""
      },
      "item4": {
        "mainTitle": '操作',
        "title": "付款設置",
        "info": ""
      },
      "item5-1": {
        "title": "地址",
        "info": ""
      },
      "item5": {
        "title": "編輯備註",
        "info": ""
      },
      "item6": {
        "title": "編輯地址",
        "info": ""
      },
      "item7": {
        "title": "自動提現",
        "info": ""
      },
      "item8": {
        "title": "同步地址",
        "info": ""
      },
      "item9": {
        "title": "地址設置",
        "info": ""
      },
      "item10": {
        "title": "請輸入你的aleo地址",
        "info": ""
      },
      "item11": {
        "title": "當前支付閾值",
        "info": ""
      },
      "item12": {
        "title": "開啟支付",
        "info": ""
      },
      "item13": {
        "title": "關閉支付",
        "info": ""
      },
      "item14": {
        "title": "設置支付閾值",
        "info": ""
      },
      "item15": {
        "title": "修改aleo挖礦賬戶支付閾值為",
        "info": ""
      },
      "item16": {
        "title": "請查看發送給您的電子郵件，並在24小時內點擊郵件中的確認鏈接來激活您的新地址。付款將暫停，直至激活。",
        "info": ""
      },
      "item16-1": {
        "title": "您的地址更改尚未激活",
        "info": ""
      },
      "item17": {
        "title": '請先開啟兩步驗證'
      }
    },
    "securitySetting": {
      "state": {
        "seted": '已設定',
        "opened": '已開啟',
        "open": "開啟",
        "closed": '未開啟',
        "close": '關閉'
      },
      "item1": {
        "title": "安全設置",
        "info": ""
      },
      "item2": {
        "title": "密碼登錄",
        "info": "用於保護帳戶安全。"
      },
      "item3": {
        "title": "已設置",
        "info": ""
      },
      "item4": {
        "title": "修改",
        "info": ""
      },
      "item4-1": {
        "title": "當前密碼",
        "info": ""
      },
      "item4-2": {
        "title": "新密碼",
        "info": ""
      },
      "item4-3": {
        "title": "再次輸入新密碼",
        "info": ""
      },
      "item4-4": {
        "title": "雙重驗證動態口令",
        "info": ""
      },
      "item5": {
        "title": "雙重驗證",
        "info": "用於登入帳戶、增加或修改付款地址址。"
      },
      "item5-1": {
        // "title":"為了您的賬戶安全，請關聯您的Google",
        "step": "第一步",
        "step2": "第二步",
        "next": "下一步",
        "Previous": "上一步",
        "title": "開啟雙重驗證",
        "info": ""
      },
      "item5-2": {
        // "title":"下載並安裝Google身份驗證器應用",
        "title": "請使用您手機上的Google身分驗證器（Google Authenticator）或其它相容應用程式掃描下方QR Code，也可手動輸入以下16位密鑰。",
        "info": ""
      },
      "item5-3": {
        "title": "請妥善保存密鑰，避免被盜或丟失。如遇手機丟失等情況，可透過該密鑰回復您的Google驗證。如密鑰丟失，需要提交工單透過人工客服重設，處理時間需7天。",
        // "title":"打開Google Authenticator應用並掃瞄下方二維碼",
        "info": ""
      },
      "item5-4": {
        "title": "如果您無法掃描二維碼，您可以在 Google Authenticator 應用程序中手動輸入16位密鑰",
        "info": ""
      },
      "item5-5": {
        "title": "密鑰",
        "info": ""
      },
      "item5-6": {
        "title": "復製",
        "info": ""
      },
      "item5-7": {
        "title": "備份密鑰",
        "info": ""
      },
      "item5-8": {
        "title": "密鑰用於恢復您的 Google 驗證，請記錄16位密鑰並保存在安全的地方",
        "info": ""
      },
      "item5-9": {
        "title": "綁定谷歌身份驗證器",
        "info": ""
      },
      "item5-10": {
        "title": "郵箱驗證碼",
        "info": ""
      },
      "item5-11": {
        "title": "請輸入郵箱驗證碼",
        "info": ""
      },
      "item5-12": {
        "title": "獲取驗證碼",
        "info": ""
      },
      "item5-13": {
        "title": "雙重驗證",
        "info": ""
      },
      "item6": {
        "title": "防釣魚碼",
        "info": "开启后，所有真正来自ZKLion的邮件都会显示防钓鱼码以防止网络钓鱼诈骗"
      },
      "item6-1": {
        "title": "你的防釣魚碼",
        "info": ""
      },
      "item6-2": {
        "title": "輸入你的防釣魚碼",
        "info": ""
      },
    },
    "personalCenter": {
      'title': '個人設置',
      "item1": {
        "title": "賬戶",
        "info": ""
      },
      "item2": {
        "title": "登陸歷史",
        "info": ""
      },
      "item3": {
        "title": "時間",
        "info": ""
      },
      "item4-1": {
        "title": "國家",
        "info": ""
      },
      "item4": {
        "title": "位置",
        "info": ""
      },
      "item5": {
        "title": "設備",
        "info": ""
      },
      "item6": {
        "title": "頁",
        "info": ""
      }
    }
  },
  soloIndexPage: {
    "item1": {
      "title": "查詢",
      "info": ""
    },
    "item2": {
      "title": "輸入您的挖礦錢包地址",
      "info": ""
    },
    "item3": {
      "title": "輸入您的錢包地址即可查看您礦機相關的實時挖礦數據，如算力變化、機器運行情況、總收益等",
      "info": ""
    },
    "item4": {
      "title": "全網算力",
      "info": ""
    },
    "item5": {
      "title": "Solo算力",
      "info": ""
    },
    "item6": {
      "title": "Aleo價格",
      "info": ""
    },
    "item7": {
      "title": "Solo費率",
      "info": ""
    },
    "item8": {
      "title": "操作手冊",
      "info": ""
    },
    "item9": {
      "title": "下載挖礦軟件",
      "info": ""
    },
    "item10": {
      "title": "請根據您礦機運行的操作系統下載正確的軟件版本，立即體驗Aleo挖礦帶來的高收益！",
      "info": ""
    },
    "item11": {
      "title": "挖礦代理",
      "info": ""
    },
    "item12": {
      "title": "您可以以最低延遲鏈接以下代理地址，確保您的挖礦利潤最大化。",
      "info": ""
    },
    "item13": {
      "title": "技術支持",
      "info": ""
    },
    "item14": {
      "title": "如果您在配置過程中遇到任何問題，請聯系我們。我們將協助您成功接入礦池。",
      "info": ""
    },
    "item15": {
      "title": "掃描二維碼聯系我們。",
      "info": ""
    }
  },
  soloDetailPage: {
    Dataoverview: {
      item1: "30分鐘平均算力",
      item2: "24小時平均算力",
      item3: "纍計收益",
      item4: "Solution總數"
    },
    PowerView: {
      title: {
        info:'算力、收益變化趨勢',
        item_3m: "過去24小時算力",
        item_3h: "過去24小時算力",
        item_1d: "過去30天算力"
      },
      time_3m: "30分鍾",
      time_3h: "3小時",
      time_1d: "1天",
      item1: "收益情況",
      item2: "算力情況"
    },
    MachineList: {
      title: "機器列錶",
      tab1: "全部",
      tab2: "在線",
      tab3: "離線",
      TabList: {
        item1: "礦機名稱",
        item2: "15分鐘算力 (s/s)",
        item3: "1小時算力 (s/s)",
        item4: "24小時算力 (s/s)",
        item5: "最近提交時間"
      },
    },
    BlockSolution: {
      title: "Accepted Solutions",
      TabList: {
        item1: "區塊高度",
        item2: "Epoch",
        item3: "Commitment",
        item4: "時間",
        item5: "Target",
        item6: "Reward"
      },
    },
  },
  button: {
    return: "返回",
    resend_email: '重發郵件',
    reset_pwd: "重製密碼",
    confirm: "確認",
    cancel: '取消',
    add: '添加',
    move: '移動主機',
    del: '刪除主機',
    cancel_change: "取消變更",
	informedConsent: "我已知曉",
    
  },
  news: {
    "announcement": '公告',
  },
  ...site_zh,
  tableLocale: 'zh-CN',
  pagination:{ 
    perpagesize: {
      left: '',
      right: '每頁條數',
      total: '共 ',
      record:' 條'
    }
  }
}




    
    
    
export default zh